globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"W_IYQQSja54GCc3NOnGXj"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://81f71229c1d6416da952a9956ce3179d@o230185.ingest.sentry.io/1388457',

  environment: process.env.AII_ENV,

  // Enabled only in cloud environments
  enabled: !!process.env.SENTRY_ENABLED,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE || '') || 0,

  replaysOnErrorSampleRate: 0.45,

  // This sets the sample rate to be 2.5%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.025,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  ignoreErrors: [/ResizeObserver loop limit exceeded/, /ChunkLoadError/],

  maxValueLength: 3000,
});
